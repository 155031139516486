@use "style/main";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.content {
  display: flex;
  height: 100%;
  width: 100%;
}

.allow-cr {
  white-space: pre;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
